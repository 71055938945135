


interface Props {
  text: string
}

const Translations = ({ text }: Props) => {
  
  

  return <>{text}</>
}

export default Translations
