// ** React Imports
import { useState, SyntheticEvent, Fragment, useEffect } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Context
import { useAuth } from 'src/hooks/useAuth'

// ** Type Imports
import { Settings } from 'src/@core/context/settingsContext'
import ProtectedRoute from 'src/context/ProtectedRoute'
import { supabase } from 'src/configs/supabaseClient'
import { useGetProfileImage } from 'src/hooks/useGetProfileImage'
import { useGetEmployeeByEmail } from 'src/hooks/employees/useGetEmployeeByEmail'

interface Props {
  settings: Settings
}

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = (props: Props) => {
  const { settings } = props
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const router = useRouter()
  const auth = useAuth()
  const {
    data: employeeData,
    isLoading: isLoadingEmployee,
    isError: isErrorEmployee,
    refetch: refetchEmployee
  } = useGetEmployeeByEmail(auth?.userInfo?.email || '');

  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      mr: 2,
      fontSize: '1.375rem',
      color: 'text.primary'
    }
  }

  const handleLogout = async () => {
    await auth?.logout()
    handleDropdownClose()
  }

  return (
    <ProtectedRoute>
      <Fragment>
        <Box
          onClick={handleDropdownOpen}
          sx={{ ml: 2, cursor: 'pointer', display: 'flex', alignItems: 'start' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
          <Typography sx={{ fontWeight: 600 }}>{employeeData?.userName}</Typography>
          <Typography variant='body2' sx={{ fontSize: '12px', color: 'text.disabled' }}>
            {employeeData?.email}
          </Typography>
          </Box>
          <Icon icon='mdi:chevron-down' />
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleDropdownClose()}
          sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
          anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        >
          <Box sx={{ pt: 2, pb: 3, px: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>

              <Box sx={{ display: 'flex', ml: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 600 }}>
                  {employeeData?.userName}
                </Typography>
                <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                  {employeeData?.email}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ mt: '0 !important' }} />
          <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/profile")}>
            <Box sx={styles}>
              <Icon icon='mdi:account-outline' />
              Profile
            </Box>
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleLogout();
            }}
            sx={{ py: 2, '& svg': { mr: 2, fontSize: '1.375rem', color: 'text.primary' } }}
          >
            <Icon icon='mdi:logout-variant' />
            Logout
          </MenuItem>
        </Menu>
      </Fragment>
    </ProtectedRoute>
  )
}

export default UserDropdown
