// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {

  return [
    {
      title: 'Dashboard',
      path: '/',
      icon: 'mdi:home-outline',
    },
    {
      title: 'Companies',
      icon: 'mdi:domain',
      children: [

        {
          title: 'All Companies',
          path: '/companies'
        },
        {
          title: 'Add Company',
          path: '/companies/add-company'
        }
      ]
    },
    {
      title: 'Employees',
      icon: 'mdi:user-outline',
      children: [

        {
          title: 'All Employees',
          path: '/employee/list'
        },
        {
          title: 'Add Employee',
          path: '/employee/add-employee'
        }
      ]
    },
    {
      title: 'Roles & Permissions',
      path: '/roles',
      icon: 'mdi:shield-outline',
    },
    {
      title: 'Reminders',
      path: '/reminders',
      icon: 'mdi:notifications',
    },
    {
      title: 'Audit Logs',
      path: '/audit-logs',
      icon: 'mdi:shield-outline',
    },
    {
      title: 'Monitor FMCSA',
      path: '/monitor-fmcsa',
      icon: 'mdi:eye-outline',
    },
    // {
    //   title: 'SIM Card Management',
    //   path: '/dq-file',
    //   icon: 'mdi:file-outline',
    // },
  ]
}

export default navigation
