"use client";
import { supabase } from 'src/configs/supabaseClient';
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useAuth } from '../useAuth';

// Define the structure for a single employee
interface EmployeeData {
  id: number;
  userName: string;
  email: string;
  can_manage: string;
  phoneNumber: string;
  user_id: string;
  unique_code: string;
}

// email character varying(255) not null,
// "userName" text not null,
// role character varying(50) not null default 'NEW_USER_BLANK'::character varying,
// profileimage character varying(255) null,
// "phoneNumber" text null,
// user_id uuid null,
// unique_code text null,
// unique_code_expires date null,
// unique_code_created_at date null,

export const useGetEmployeeByEmail = (email: string) => {
  const auth = useAuth();

  return useQuery({
    //queryKey: ['employeeByEmail'],
    queryKey: ['employeeByEmail', email],
    queryFn: async () => {

      try {
        const { data, error } = await supabase
          .from('user_information')
          .select('id, userName, email, can_manage, phoneNumber, user_id, unique_code')
          .eq('email', email);

        if (error) {
          console.error('Supabase error:', error);
          throw new Error(error.message);
        }

        // Return the first employee that matches the email
        return data ? data[0] : null;
      } catch (error) {
        console.error('Supabase query failed', error);
        throw error; // rethrow the error to make React Query catch it
      }
    },
    enabled: !!email, // Only run the query if the user is logged in
  });
};

