// import { createClient } from '@supabase/supabase-js';

// // Get your Supabase URL and KEY from the .env file or your Supabase project settings


// let url, anon_key;

// console.log('log-env', process.env.NODE_ENV);

// if (process.env.NODE_ENV === 'development') {
//   url = 'http://127.0.0.1:54321';
//   anon_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0'
// } else if (process.env.NODE_ENV === 'production') {
//   url = 'https://fqdztmfcuozatvtytsix.supabase.co'
//   anon_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZxZHp0bWZjdW96YXR2dHl0c2l4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE4MTcwODYsImV4cCI6MjAxNzM5MzA4Nn0.Wmo147dyXWFhIm_iTK-akjonJz4_a7KrUX2s-8RzBqo'
// }

// // Ensure that url and anon_key are not undefined before proceeding
// if (!url || !anon_key) {
//   throw new Error('Supabase URL and Anon Key must be provided.');
// }

// const supabase = createClient(url, anon_key);

// export { supabase };

import { createClient } from '@supabase/supabase-js';

// Get your Supabase URL and KEY from the .env file or your Supabase project settings
const SUPABASE_URL = process.env.NEXT_PUBLIC_SUPABASE_URL;
const SUPABASE_ANON_KEY = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;

if (!SUPABASE_URL || !SUPABASE_ANON_KEY) {
  throw new Error('Missing Supabase environment variables.');
}

// NEXT_PUBLIC_SUPABASE_URL=https://fqdztmfcuozatvtytsix.supabase.co
// NEXT_PUBLIC_SUPABASE_ANON_KEY=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZxZHp0bWZjdW96YXR2dHl0c2l4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE4MTcwODYsImV4cCI6MjAxNzM5MzA4Nn0.Wmo147dyXWFhIm_iTK-akjonJz4_a7KrUX2s-8RzBqo

const url = 'https://fqdztmfcuozatvtytsix.supabase.co'
const anon_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZxZHp0bWZjdW96YXR2dHl0c2l4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE4MTcwODYsImV4cCI6MjAxNzM5MzA4Nn0.Wmo147dyXWFhIm_iTK-akjonJz4_a7KrUX2s-8RzBqo'

// Create a single Supabase client for interacting with your database
const supabase = createClient(url, anon_key);

export { supabase };
