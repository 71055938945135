import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from 'src/hooks/useAuth'; // Adjust the path accordingly
import { Box } from '@mui/system';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const auth = useAuth();
  const router = useRouter();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!auth?.user && isInitialized) {
      router.replace('/auth/login');
    } else {
      setIsInitialized(true);
    }
  }, [auth?.user, isInitialized, router]);

  if (!isInitialized || !auth?.user) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}></Box>;
  }

  return <>{children}</>;
};


export default ProtectedRoute;

