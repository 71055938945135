import React, { useEffect, useState, createContext, useContext, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useUser, useSupabaseClient } from '@supabase/auth-helpers-react';

type AuthContextType = {
  user: any;
  userInfo: any;
  error?: any;
  logout: () => Promise<void>;
  checkSession: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType | null>(null);

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const userResult = useUser();
  const [userInfo, setUserInfo] = useState(null);
  const supabase = useSupabaseClient();
  const router = useRouter();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
    };

    fetchSession();
  }, [supabase.auth]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (userResult) {
        try {
          const { data, error } = await supabase
            .from('user_information')
            .select('*')
            .eq('user_id', userResult?.id)
            .single();
          if (error) throw error;
          setUserInfo(data);
        } catch (error) {
          console.error('Error fetching user information:', error);
        }
      }
    };

    fetchUserInfo();
  }, [userResult, supabase]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    router.push('/auth/login');
  };

  const checkSession = async () => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (!session?.session?.user) {
        router.push('/auth/login');
      }
    } catch (error) {
      console.error('Error checking session:', error);
    }
  };

  const values = {
    user: userResult,
    userInfo,
    logout: handleLogout,
    checkSession
  };

  return (
    <AuthContext.Provider value={values}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export { AuthContext, AuthProvider };


